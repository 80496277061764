<template>
  <a-modal :title='formTitle' :width='800' :visible='visible' :confirmLoading='confirmLoading' @ok='handleOk'
           @cancel='close'
           cancelText='关闭'>

    <a-spin :spinning='confirmLoading'>
      <a-form-model  ref='form' :model='form' :rules='rules'>
        <a-form-model-item label='分类名称' prop='categoryName'>
          <a-input v-model='form.categoryName' placeholder='请输入分类名称' />
        </a-form-model-item>
        <a-form-model-item label='上级分类' prop='pid' v-if='this.type==2'>
          <a-tree-select v-model='form.pid'
                         showSearch
                         style='width: 300px'
                         :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                         placeholder='请选择上级分类'
                         allowClear
                         treeDefaultExpandAll
                         treeNodeFilterProp='title'
                         @change='onChangePid'
          >
            <a-tree-select-node v-for='(category,index) in categoryList' :title='category.categoryName'
                                :value='category.id' :key='category.id'>
            </a-tree-select-node>
          </a-tree-select>
        </a-form-model-item>

        <!--      <a-form-model-item label='分类图标' prop='icon'>-->
        <!--        <a-input v-model='form.icon' placeholder='请输入分类图标' />-->
        <!--      </a-form-model-item>-->
        <a-form-model-item label='分类图标' prop='icon' >
          <a-upload listType='picture-card'
                    class='avatar-uploader' :show-upload-list='false'
                    :beforeUpload='(info) => fileBeforeUpload("icon",info)'
                    :customRequest='(info) => fileUploadRequest("icon",info)' v-model='form.icon'>
            <img v-if='form.icon != null' :src='form.icon' style='height:104px;max-width:300px' />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-model-item label='图文照片' prop='categoryPic' >
          <a-upload listType='picture-card'
                    class='avatar-uploader' :show-upload-list='false'
                    :beforeUpload='(info) => fileBeforeUpload("categoryPic",info)'
                    :customRequest='(info) => fileUploadRequest("categoryPic",info)' v-model='form.categoryPic'>
            <img v-if='form.categoryPic != null' :src='form.categoryPic' style='height:104px;max-width:300px' />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
            </div>
          </a-upload>
        </a-form-model-item>
        <!--      <a-form-model-item label='分类图片' prop='categoryPic'>-->
        <!--        <a-input v-model='form.categoryPic' placeholder='请输入分类图片' />-->
        <!--      </a-form-model-item>-->
        <a-form-model-item label='分类描述' prop='content'>
          <a-textarea v-model='form.content' :rows='3' showCount :maxlength='80' />
        </a-form-model-item>
        <a-form-model-item label='排序' prop='sort'>
          <a-input-number style='width: 150px' v-model='form.sort' placeholder='请输入排序' :min='1' :maxLength='10' :max='9999' />
        </a-form-model-item>
        <!--        <div class='bottom-control'>-->
        <!--          <a-space>-->
        <!--            <a-button type='primary' :loading='submitLoading' @click='submitForm'>-->
        <!--              保存-->
        <!--            </a-button>-->
        <!--            <a-button type='dashed' @click='cancel'>-->
        <!--              取消-->
        <!--            </a-button>-->
        <!--          </a-space>-->
        <!--        </div>-->
      </a-form-model >
    </a-spin>
  </a-modal>
</template>

<script>

import {
  getGraphCategory,
  addGraphCategory,
  updateGraphCategory,
  pageGraphCategory,
  listGraphCategory
} from '@/api/dynamic/graphCategory'
import Editor from '@/components/Editor'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'

export default {
  name: 'CrudForm',
  components: {
    Editor
  },
  data() {
    return {
      visible: false,         // 弹窗显示控制
      confirmLoading: false,  // 提交后加载, 防止短时间内重复提交
      submitLoading: false,
      formTitle: '',
      type: 1,
      loading: false,
      categoryList: [],
      // 表单参数
      form: {
        id: null,
        categoryName: null,
        pid: null,
        tuneId: null,
        level: null,
        content: null,
        sort: null,
        icon: null,
        categoryPic: null
      },
      // 1增加,2修改
      formType: 1,
      rules: {
        categoryName: [
          {
            required: true, message: '分类名称不能为空', trigger: 'blur'
          }
        ],
        level: [
          {
            required: true, message: '分类等级不能为空', trigger: 'blur'
          }
        ],
        // content: [
        //   {
        //     required: true, message: '分类描述不能为空', trigger: 'blur'
        //   }
        // ],
        // icon: [
        //   {
        //     required: true, message: '分类图标不能为空', trigger: 'blur'
        //   }
        // ],
        // categoryPic: [
        //   {
        //     required: true, message: '分类图片不能为空', trigger: 'blur'
        //   }
        // ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     * @param {*} type 数据
     */
    audit(type) {
      this.reset();
      this.type = type;
      if (type == 1) {
        this.formTitle = '添加一级分类'
      }else{
        this.formTitle = '添加二级分类'
        this.getCategoryValue(0)
      }
      this.confirmLoading = false
      this.visible = true
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
      this.reset()
    },
    /**
     * 提交
     */
    handleOk() {
      if(this.form.pid != null && this.form.pid != 0 && !this.form.icon){
        this.$message.warn(
          '请添加分类图标',
          3
        )
        return ;
      }else if(this.form.pid != null && this.form.pid != 0 && !this.form.categoryPic){
        this.$message.warn(
          '请添加分类图文',
          3
        )
        return ;
      }
      this.confirmLoading = true

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          addGraphCategory(this.form).then(response => {
            this.$message.success(
              '新增成功',
              3
            )
            this.visible = false
            this.$emit('close')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    },

    onChangePid(value) {
      this.form.pid = value
    },
    //初始化商品分类数据
    getCategoryValue(pid) {
      const that = this
      listGraphCategory({ pid: pid }).then(res => {
        if (res.success) {
          that.categoryList = []
          that.categoryList = res.data
          console.log(that.categoryList)
        } else {
          console.log(res.message)
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.type = 1
      this.form = {
        id: null,
        categoryName: null,
        tuneId: null,
        pid: null,
        level: null,
        content: null,
        sort: null,
        icon: null,
        categoryPic: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.getCategoryValue(0)
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGraphCategory({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.getCategoryValue(0)
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field, fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片')
        return false
      }
      return true
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field, fileInfo) {
      Log.info('uploadfileInfo', fileInfo)
      // debugger
      this.loading = true
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res)
          this.loading = false
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url
        }
      })
    }
  }
}
</script>

<style lang='less' scoped></style>